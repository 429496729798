<template>
  <div class="hello">
    <div class="img">
      <img src="../assets/shhg.jpg" alt="">
    </div>
    <div class="introduction">
      <div class="introductionBox">
        <div class="left_bg"></div>
        <div class="right_bg"></div>
        <h1 class="inner_t">
          <span style="float: left;">首页 &nbsp;</span>
          <p style="float: left;">&gt;</p>
          <span style="float: left;">&nbsp;&nbsp;技术</span>
        </h1>
        <div class="list">
          <p style="color: peru;font-size: 18px;padding-left: 20px;">产品列表</p>
          <div class="tab">
            <ul class="title" id="title">
              <li class="current">
                <div class="div"  @click="Tablist(0)"><span class=".sp">大量</span></div>
              </li>
              <li>
                <div class="div" @click="Tablist(1)"><span class=".sp">中量</span></div>
              </li>
              <li>
                <div class="div" @click="Tablist(2)"><span class=".sp">微量</span></div>
              </li>
              <li>
                <div class="div" @click="Tablist(3)" style="border-right: none;"><span class=".sp">微生物菌剂</span></div>
              </li>
            </ul>
            <div class="compound" v-show="number == 0">
              <h2 style="padding-left: 20px">上禾化工大量复合肥</h2>
            </div>
            <div class="compound" v-show="number == 1">
              <h2 style="padding-left: 20px">上禾化工中微量元素水溶肥</h2>
            </div>
            <div class="compound" v-show="number == 2">
              <h2 style="padding-left: 20px">上禾化工微量元素水溶肥</h2>
            </div>
            <div class="compound" v-show="number == 3">
              <h2 style="padding-left: 20px">上禾化工微生物菌剂</h2>
            </div>
            <div class="box" id="box">
              <div class="in1" style="display: block;">
                <ul class="pro_shop">
                  <router-link :to="{path:'/ProductDetails',query:{
                               id:item.id
                               }}" tag="li" v-for="(item ,index) in classify" :key="index">
                    <img :src="item.url" alt="">
                    <h1>
                      <p class="aa">{{ item.name }}</p>
                    </h1>
                    <p class="pp" style="color: black;">{{ item.tedian }}</p>
                  </router-link>
                </ul>
              </div>
              <div class="in2" style="display: none">
                <ul class="pro_shop">
                  <router-link :to="{path:'/ProductDetails',query:{
                               id:item.id
                               }}" tag="li" v-for="(item ,index) in classify2" :key="index">
                    <img :src="item.url" alt="">
                    <h1>
                      <p class="aa">{{ item.name }}</p>
                    </h1>
                    <p class="pp" style="color: black;">{{ item.tedian }}</p>
                  </router-link>
                </ul>
              </div>
              <div class="in3" style="display: none">
                  <ul class="pro_shop">
                    <router-link :to="{path:'/ProductDetails',query:{
                               id:item.id
                               }}" tag="li" v-for="(item ,index) in classify3" :key="index">
                      <img :src="item.url" alt="">
                      <h1>
                        <p class="aa">{{ item.name }}</p>
                      </h1>
                      <p class="pp" style="color: black;">{{ item.tedian }}</p>
                    </router-link>
                  </ul>
              </div>
              <div class="in4">
                <ul class="pro_shop">
                  <router-link :to="{path:'/ProductDetails',query:{
                               id:item.id
                               }}" tag="li" v-for="(item ,index) in classify4" :key="index">
                    <img :src="item.url" alt="">
                    <h1>
                      <p class="aa">{{ item.name }}</p>
                    </h1>
                    <p class="pp" style="color: black;">{{ item.tedian }}</p>
                  </router-link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import $ from 'jquery';

export default {
  name: "ChemicalFertilizer",
  data() {
    return {
      number:0,
      yuansu:[{name:"大量"},{name:"中量"},{name:"微量"},{name:"微生物菌剂"}],
      classify: [
        {
          name: '大量元素水溶肥',
          tedian: '高磷型',
          id: 0,
          url: require('../assets/huafei/大量元素.jpg')
        },
        {
          name: '大量元素水溶肥',
          tedian: '高钾型',
          id: 1,
          url: require('../assets/huafei/大量元素2.jpg')
        },
        {
          name: '大量元素水溶肥',
          tedian: '平衡型',
          id: 2,
          url: require('../assets/huafei/大量元素3.jpg')
        }, {
          name: '大量元素水溶肥',
          tedian: '高氮型',
          id: 3,
          url: require('../assets/huafei/大量元素4.jpg')
        }, {
          name: '大量元素水溶肥',
          tedian: '高氮型',
          id: 4,
          url: require('../assets/huafei/大量元素5.jpg')
        },
      ],
      classify2: [
        {
          name: '中微量元素水溶肥',
          tedian: 'Ca+Mg≥10%',
          id: 5,
          url: require('../assets/huafei/中量(1).jpg')
        },
      ],
      classify3: [
        {
          name: '微量元素水溶肥',
          tedian: 'Re≥2.0g/L',
          id: 6,
          url: require('../assets/huafei/微量.jpg')
        },
      ],
      classify4: [
        {
          name: '吉五福-微生物菌剂',
          tedian: '有效活菌数≥2.0亿/克',
          id: 7,
          url: require('../assets/huafei/微生物菌剂2.jpg')
        },
        {
          name: '吉五福-微生物菌剂',
          tedian: '有效活菌数≥2.0亿/克',
          id: 8,
          url: require('../assets/huafei/微生物菌剂3.jpg')
        },
        {
          name: '吉五福-微生物菌剂',
          tedian: '有效活菌数≥2.0亿/克',
          id: 9,
          url: require('../assets/huafei/微生物菌剂.jpg')
        },
      ],
    }
  },
  mounted: function () {
    $('#title').children().click(function () {
      // console.log("1111");
      $(this).addClass('current').siblings().removeClass('current');
      $('#box').children().eq($(this).index()).show().siblings().hide();
    });
  },
  methods:{
    Tablist(index) {
      this.number = index;
    }
  }

}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: 微软雅黑;
}

.hello {
  height: 1700px;
  background-color: #dfdfdf;

}

.img {
  width: 100%;
  height: 300px;
}

.img img {
  width: 100%;
  height: 100%;
}

.introduction {
  width: 100%;
  position: absolute;
  top: 260px;
}

.introductionBox {
  width: 1200px;
  /*border: 1px solid red;*/
  margin: auto;
  height: 1500px;
  background-color: white;
  position: relative;
}

.left_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat;
  position: absolute;
  left: -28px;
  top: 0px;
}

.right_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat -29px 0px;
  position: absolute;
  right: -28px;
  top: 0px;
}

.inner_t {
  height: 35px;
  background-color: #f5f5f5;
  line-height: 35px;
  color: #30313a;
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #cecece;
  padding: 0 100px;
}

.inner_t span:hover {
  color: red;
  cursor: pointer;
}

.list {
  width: 1000px;
  height: 1500px;
  margin: 50px auto;
  /*border: 1 px solid;*/
}

.list p:hover {
  cursor: pointer;
}

.title {
  list-style: none;
  /*/ / padding: 50 px 0 0 50 px;*/
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.title li {
  width: 25%;
  height: 70px;
  text-align: center;
  line-height: 70px;
  margin-bottom: 10px;
}

.title li span {
  width: 72px !important;
  height: 50px;
  border-bottom: 1px solid #eadedd;
  /*/ / border-right: 1 px solid white;*/
  padding: 26px 70px;
  font-size: 18px;

}

.title li .div {
  width: 250px;
  height: 45px;
  border-right: 1px solid #eadedd;
}

.title li .div:nth-child(4) {
  border-right: none !important;
}

.title li:hover {
  cursor: pointer;
}

.current {
  width: 250px !important;
  height: 70px !important;
  border-right: 1px solid white !important;
  background-color: #d9232d;
  border: none;
  color: white;
}

.compound {
  width: 990px;
  height: 60px;
  background-color: #f5f5f5;
  margin: 20px auto;
}

.compound h2 {
  line-height: 60px;
}

.box div {

  display: none;
}

.pro_shop {
  width: 910px;
  list-style: none;
  margin: 39px auto;
}

.pro_shop li {
  width: 301px;
  height: 360px;
  float: left;
  border: 1px solid #e2e2e2;
  margin: -1px 0 0 -1px;
  text-align: center;

}

.pro_shop li img {
  margin-top: 24px;
  height: 213px;
}

.aa {
  color: #39393c;
  font-size: 18px;
  text-align: center;
  text-decoration: none;

}

.aa:hover {
  color: #d9232d;
  text-decoration: underline red !important;
}

.pp {
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  padding-left: 70px;
  width: 224px;
  margin-top: 6px;
  height: 60px;
  display: block;
  overflow: hidden;
}
</style>