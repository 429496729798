<template>
  <div style="  background-color: #dfdfdf;">

    <section>
      <div class="bg_img">
        <img src="../assets/shhg.jpg" alt="" width="100%" height="300px">
      </div>
      <div class="main">
        <div class="product">
          <div class="left_bg"></div>
          <div class="right_bg"></div>
          <h1 class="main_text">
            <a href="/">首页</a>
            >
            <router-link to="/"><a href="#">产品中心</a></router-link>
          </h1>
          <div class="main_news">
            <div class="DeatilImg Deatil">
              <img :src="arr[id].url" alt=""  height="80%">
             

            </div>
            <div class="DeatilText Deatil">
              <h1>{{ arr[id].name1 }}</h1>
              <p>{{arr[id].type}}</p>
              <p>{{arr[id].kg}}</p>
              <div class="DeatilBtn">
                <router-link tag="a" to="/ChemicalFertilizer">查看其它产品</router-link>
              </div>
            </div>
            <p class="clr"></p>
            <div class="Overview">
              <div class="Overview_tab">
                <a-tabs default-active-key="1" @change="callback" class="Overview_tabs">
                  <a-tab-pane key="1" tab="产品概述">
                    <p>
                      <strong>
                      <span style="font-family: 宋体;font-size: 14px">
                        <span style="font-family:宋体;font-weight: bolder;">产品名称：</span>
                        <span>{{arr[id].name1}}</span>
                      </span>
                      </strong>
                      <span style="font-family: 宋体;font-size: 14px">
                      {{ arr[id].name }}
                    </span>

                    </p>
                    <p>
                      <strong>
                      <span style="font-family: 宋体;font-size: 14px">
                        <span style="font-family:宋体;font-weight: bolder; ">类&nbsp;&nbsp;型</span>
                      </span>
                      </strong>
                      <span style="font-family: 宋体;font-size: 14px">
                      <span style="font-family:宋体 ">：{{ arr[id].type }}</span>
                    </span>
                    </p>

                    <p v-show="arr[id].abs">
                      <strong>
                      <span style="font-family: 宋体;font-size: 14px">
                        <span style="font-family:宋体;font-weight: bolder; ">技术指标</span>
                      </span>
                      </strong>
                      <span style="font-family: 宋体;font-size: 14px">
                      <span style="font-family:宋体 ">：{{ arr[id].jszb }}</span>
                    </span>
                    </p>

                    <p v-show="arr[id].abs">
                      <strong>
                      <span style="font-family: 宋体;font-size: 14px">
                        <span style="font-family:宋体;font-weight: bolder; ">净 含 量</span>
                      </span>
                      </strong>
                      <span style="font-family: 宋体;font-size: 14px">
                      <span style="font-family:宋体 ">：{{ arr[id].kgs }}</span>
                    </span>
                    </p>

                    <p>
                      <strong>
                      <span style="font-family: 宋体;font-size: 14px">
                        <span style="font-family:宋体;font-weight: bolder; ">适用范围：</span>
                      </span>
                      </strong>
                      <span style="font-family: 宋体;font-size: 14px">
                      <span style="font-family:宋体 ">{{ arr[id].range }}</span>
                    </span>
                    </p>
                    <p>
                      <strong>
                      <span style="font-family: 宋体;font-size: 14px">
                        <span style="font-family:宋体;font-weight: bolder; ">使用方法：</span>
                      </span>
                      </strong>
                      <span style="font-family: 宋体;font-size: 14px">
                      <span style="font-family:宋体 ">{{ arr[id].reference }}</span>
                    </span>
                    </p>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="产品特点" force-render>

                    <p style="text-indent:2em;">
                      <span style="font-family: 宋体;font-size: 14px;">{{arr[id].trait}}</span>
                    </p>
                    <p style="text-indent:2em;">
                      <span style="font-family: 宋体;font-size: 14px;">{{arr[id].trait1}}</span>
                    </p>
                    <p style="text-indent:2em;">
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].trait2}}</span>
                    </p>
                    <p style="text-indent:2em;">
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].trait3}}</span>
                    </p>
                  </a-tab-pane>
                  <a-tab-pane key="3" tab="注意事项">
                    <p>
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].notice}}</span>
                    </p>
                    <p>
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].notice1}}</span>
                    </p>
                    <p>
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].notice2}}</span>
                    </p>
                    <p>
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].notice3}}</span>
                    </p>
                    <p>
                      <span style="font-family: 宋体;font-size: 14px">{{arr[id].notice4}}</span>
                    </p>
                  </a-tab-pane>
                </a-tabs>
                <p class="clr"></p>
              </div>
              <h3 class="other_text">
                <img class="vm" src="../assets/pro_top.png" alt="">
                <span class="vm">同类其它产品</span>
              </h3>
              <div class="Othertypes">
                <div class="Types">
                  <ul class="Types_shop">
<!--                   <router-link tag="li" v-for="(item,index) in arrlist" @click="chanPin(item.id)" :key="index" :to="{name:`ProductDetails`,query:{id:item.id}}">-->
<!--                    {{item}}-->
                    <li v-for="(item,index) in arrlist" @click="chanPin(item.id)" :key="index">
                      <img :src="item.url" alt="">
                       <div class="InfoShop">
                         <h1>{{ item.name1}}</h1>
                       </div>
                    </li>
<!--                   </router-link>-->
                  </ul>
                </div>
              </div>
              <p class="clr"></p>
              <div class="Knowledge">
                <ul class="Knowledge_list">
                  <li>
                    <img src="../assets/txt_ioc.png" alt="">
                    <span>
                    <a href="#">油菜施肥技术</a>
                  </span>
                  </li>
                  <li>
                    <img src="../assets/txt_ioc.png" alt="">
                    <span>
                    <a href="#">油菜施肥技术</a>
                  </span>
                  </li>
                  <li>
                    <img src="../assets/txt_ioc.png" alt="">
                    <span>
                    <a href="#">油菜施肥技术</a>
                  </span>
                  </li>
                  <li>
                    <img src="../assets/txt_ioc.png" alt="">
                    <span>
                    <a href="#">油菜施肥技术</a>
                  </span>
                  </li>
                  <li>
                    <img src="../assets/txt_ioc.png" alt="">
                    <span>
                    <a href="#">油菜施肥技术</a>
                  </span>
                  </li>
                  <li>
                    <img src="../assets/txt_ioc.png" alt="">
                    <span>
                    <a href="#">油菜施肥技术</a>
                  </span>
                  </li>
                </ul>
              </div>
              <div style="height: 160px"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "ProductDetails",
  data() {
    return {
      id: '',
      arrlist:[],
      arr: [
        {
          id:0,
          name2: '上禾化肥',
          url: require('../assets/huafei/大量元素.jpg'),
          name1: ' 大量元素水溶肥料\n',
          type: "高磷型（11-52-7+TE+RE)",
          range: '番茄，黄瓜、甜瓜、茄子、西瓜、西葫芦、梨、苹果、葡萄、桃樱桃、山橙等各种瓜果蔬菜、观赏植物和经济作物。',
          reference: "冲施、滴灌，本品营养搭配合理，适合作物生长中后期、膨果期使用,每亩每次5-10公斤。叶面喷施:蔬菜类800-1200倍稀释.果树类1000-1500倍稀释",
          trait:'本品采用国际先进的多元稀技术精制而成，是一种复方多维高能量水溶肥。具有用量小、活性高、全水溶、易吸收、利用率高等特点，适用于各种地力件栽培下的瓜果蔬菜及经济作物。' ,
          trait1:'本品所用原料均采自于全球知名供应商，纯净度高、稳定性好、无残留、无污染。是发展高效农业，生产绿色农产品首选品利。' ,
          trait2:'含有抗逆因子，有机营养等活性物质，提高作物的抗病、抗逆能力，改善作物品质。',
          trait3:'本品营养均衡，适合作物生长中后期使用，膨果、增色、光洁果面、促进花芽分化、增产增质。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice1:'打开后露置于空气中容易吸潮结块，但不影响效果。',
          notice2:"本品可与大多数农药混合使用，避免与强碱性农药混合。",
          notice3:'推荐的使用方法基于一般经验，可以随当地的气候和土壤条件以及作物的类型适当变化。',
          notice4:"本品实行限量元素标准(NY1110)-2010。",
          abs:false
        },
        {
          id:1,
          name2: '上禾化肥',
          url: require('../assets/huafei/大量元素2.jpg'),
          name1: ' 大量元素水溶肥料\n',
          type: "高钾型(13-7-40+TE+RE)",
          range: '番茄，黄瓜、甜瓜、茄子、西瓜、西葫芦、梨、苹果、葡萄、桃樱桃、山橙等各种瓜果蔬菜、观赏植物和经济作物。',
          reference: "冲施、滴灌，本品营养搭配合理，适合作物生长中后期、膨果期使用,每亩每次5-10公斤。叶面喷施:蔬菜类800-1200倍稀释.果树类1000-1500倍稀释",
          trait:'本品采用国际先进的多元稀技术精制而成，是一种复方多维高能量水溶肥。具有用量小、活性高、全水溶、易吸收、利用率高等特点，适用于各种地力件栽培下的瓜果蔬菜及经济作物。' ,
          trait1:'本品所用原料均采自于全球知名供应商，纯净度高、稳定性好、无残留、无污染。是发展高效农业，生产绿色农产品首选品利。' ,
          trait2:'含有抗逆因子，有机营养等活性物质，提高作物的抗病、抗逆能力，改善作物品质。',
          trait3:'本品营养均衡，适合作物生长中后期使用，膨果、增色、光洁果面、促进花芽分化、增产增质。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice1:'打开后露置于空气中容易吸潮结块，但不影响效果。',
          notice2:"本品可与大多数农药混合使用，避免与强碱性农药混合。",
          notice3:'推荐的使用方法基于一般经验，可以随当地的气候和土壤条件以及作物的类型适当变化。',
          notice4:"本品实行限量元素标准(NY1110)-2010。",
          abs:false
        },
        {
          id:2,
          name2: '上禾化肥',
          url: require('../assets/huafei/大量元素3.jpg'),
          name1: ' 大量元素水溶肥料\n',
          type: "平衡型(20-20-20+TE+RE)",
          range: '番茄，黄瓜、甜瓜、茄子、西瓜、西葫芦、梨、苹果、葡萄、桃樱桃、山橙等各种瓜果蔬菜、观赏植物和经济作物。',
          reference: "冲施、滴灌，本品营养搭配合理，适合作物生长中后期、膨果期使用,每亩每次5-10公斤。叶面喷施:蔬菜类800-1200倍稀释.果树类1000-1500倍稀释",
          trait:'本品采用国际先进的多元稀技术精制而成，是一种复方多维高能量水溶肥。具有用量小、活性高、全水溶、易吸收、利用率高等特点，适用于各种地力件栽培下的瓜果蔬菜及经济作物。' ,
          trait1:'本品所用原料均采自于全球知名供应商，纯净度高、稳定性好、无残留、无污染。是发展高效农业，生产绿色农产品首选品利。' ,
          trait2:'含有抗逆因子，有机营养等活性物质，提高作物的抗病、抗逆能力，改善作物品质。',
          trait3:'本品营养均衡，适合作物生长中后期使用，膨果、增色、光洁果面、促进花芽分化、增产增质。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice1:'打开后露置于空气中容易吸潮结块，但不影响效果。',
          notice2:"本品可与大多数农药混合使用，避免与强碱性农药混合。",
          notice3:'推荐的使用方法基于一般经验，可以随当地的气候和土壤条件以及作物的类型适当变化。',
          notice4:"本品实行限量元素标准(NY1110)-2010。",
          abs:false
        },
        {
          id:3,
          name2: '上禾化肥',
          url: require('../assets/huafei/大量元素4.jpg'),
          name1: ' 大量元素水溶肥料\n',
          type: "高氮型(30-6-14+TE+RE)",
          range: '番茄，黄瓜、甜瓜、茄子、西瓜、西葫芦、梨、苹果、葡萄、桃樱桃、山橙等各种瓜果蔬菜、观赏植物和经济作物。',
          reference: "冲施、滴灌，本品营养搭配合理，适合作物生长中后期、膨果期使用,每亩每次5-10公斤。叶面喷施:蔬菜类800-1200倍稀释.果树类1000-1500倍稀释",
          trait:'本品采用国际先进的多元稀技术精制而成，是一种复方多维高能量水溶肥。具有用量小、活性高、全水溶、易吸收、利用率高等特点，适用于各种地力件栽培下的瓜果蔬菜及经济作物。' ,
          trait1:'本品所用原料均采自于全球知名供应商，纯净度高、稳定性好、无残留、无污染。是发展高效农业，生产绿色农产品首选品利。' ,
          trait2:'含有抗逆因子，有机营养等活性物质，提高作物的抗病、抗逆能力，改善作物品质。',
          trait3:'本品营养均衡，适合作物生长中后期使用，膨果、增色、光洁果面、促进花芽分化、增产增质。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice1:'打开后露置于空气中容易吸潮结块，但不影响效果。',
          notice2:"本品可与大多数农药混合使用，避免与强碱性农药混合。",
          notice3:'推荐的使用方法基于一般经验，可以随当地的气候和土壤条件以及作物的类型适当变化。',
          notice4:"本品实行限量元素标准(NY1110)-2010。",
          abs:false
        },
        {
          id:4,
          name2: '上禾化肥',
          url: require('../assets/huafei/大量元素5.jpg'),
          name1: ' 大量元素水溶肥料\n',
          type: "高氮型(30-10-10+TE+RE)",
          range: '番茄，黄瓜、甜瓜、茄子、西瓜、西葫芦、梨、苹果、葡萄、桃樱桃、山橙等各种瓜果蔬菜、观赏植物和经济作物。',
          reference: "冲施、滴灌，本品营养搭配合理，适合作物生长中后期、膨果期使用,每亩每次5-10公斤。叶面喷施:蔬菜类800-1200倍稀释.果树类1000-1500倍稀释",
          trait:'本品采用国际先进的多元稀技术精制而成，是一种复方多维高能量水溶肥。具有用量小、活性高、全水溶、易吸收、利用率高等特点，适用于各种地力件栽培下的瓜果蔬菜及经济作物。' ,
          trait1:'本品所用原料均采自于全球知名供应商，纯净度高、稳定性好、无残留、无污染。是发展高效农业，生产绿色农产品首选品利。' ,
          trait2:'含有抗逆因子，有机营养等活性物质，提高作物的抗病、抗逆能力，改善作物品质。',
          trait3:'本品营养均衡，适合作物生长中后期使用，膨果、增色、光洁果面、促进花芽分化、增产增质。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice1:'打开后露置于空气中容易吸潮结块，但不影响效果。',
          notice2:"本品可与大多数农药混合使用，避免与强碱性农药混合。",
          notice3:'推荐的使用方法基于一般经验，可以随当地的气候和土壤条件以及作物的类型适当变化。',
          notice4:"本品实行限量元素标准(NY1110)-2010。",
          abs:false
        },
        {
          id:5,
          name2: '上禾化肥',
          url: require('../assets/huafei/中量(1).jpg'),
          name1: ' 中微量元素水溶肥料\n',
          type: "Ca+Mg≥10%",
          range: '果树，蔬菜，中药材，小麦，玉米，花生，棉花等。',
          reference: "底施，追施均可，果树，蔬菜，中药材每亩使用本品15-20公斤，小麦，玉米，花生，棉花每亩使用本品5-10公斤。",
          trait:'本品采用国际先进的多元稀技术精制而成，是一种复方多维高能量水溶肥。具有用量小、活性高、全水溶、易吸收、利用率高等特点，适用于各种地力件栽培下的瓜果蔬菜及经济作物，着色均匀优质早熟。' ,
          trait1:'快速缓解作物生理病害，提高抗病抗逆低温能力。多种营养元素协同作用。' ,
          trait2:'活化土壤，培肥地力，激活被土壤固定的死磷，死钾。扩大根系、强化吸收、延缓衰老、增强光合提质增产。',
          trait3:'本品营养均衡，适合作物生长中后期使用，膨果、增色、光洁果面、促进花芽分化、增产增质。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice1:'打开后露置于空气中容易吸潮结块，但不影响效果。',
          notice2:"本品可与大多数农药混合使用，避免与强碱性农药混合。",
          notice3:'推荐的使用方法基于一般经验，可以随当地的气候和土壤条件以及作物的类型适当变化。',
          notice4:"本品实行限量元素标准(NY1110)-2010。",
          abs:false
        },
        {
          id:6,
          name2: '上禾化肥',
          url: require('../assets/huafei/微量.jpg'),
          name1: ' 多元稀土\n',
          type: "Re≥2.0g/L",
          range: '多种瓜果蔬菜及大田作物。',
          reference: "叶面喷施:蔬菜类800-1200倍稀释.果树类1000-1500倍稀释",
          trait:'稀土是农业之宝，被誉为植物的超级中微量元素。本品采用镧、铈、镨、钕等稀土元素为核心原料精工合成。全水溶，易吸收，活性高，转化快。' ,
          trait1:'稀土其独特的超强磁性及催化作用能吸附空气中的二氧化碳，为植物叶片提供充足的原料资源，把蓝紫光转变为红橙光，是植物光合作用的动力来源。' ,
          trait2:'镧元素被称为植物的超级钙，具有促发新根，坚固细胞的作用。预防多种作物细菌性病害的发生。',
          trait3:'铈元素被称为植物的超级硼，具有促进花芽分化，增加花粉活性，提高植物座果率，预防果实畸形效果。',
          notice:'本品无毒无害，存放于阴凉干燥处，但仍需远离食品和儿童。',
          notice4:"本品实行限量元素标准(NY1428)-2010。",
          abs:false
        },
        {
          id:7,
          name2: '上禾化肥',
          url: require('../assets/huafei/微生物菌剂2.jpg'),
          name1: ' 吉五福-微生物菌剂\n',
          type: "有效活菌数≥2.0亿/克，全水溶腐植酸颗粒",
          kg:"净含量：10KG",
          kgs:"10KG",
          jszb:"腐植酸≥55%、富里酸≥10%、黄腐酸钾≥5%、有机质≥65%、海藻提取物≥5%、RE稀土元素≥20g/kg。",
          range: '修复土壤 刺激增长 生根壮苗 缓释增效。',
          reference: "施肥或追肥：沟施，果树：每棵2-4公斤，蔬菜类作物：每亩50-100公斤，棉花及大田粮油类作物：每亩20-40公斤",
          trait:'本品具有强大的阳离子代换量，改良土壤结构、提高蓄保能力，增强土壤通透性。' ,
          trait1:'与土壤中金属离子结合，形成水磷性团粒结构' ,
          trait2:'调酸调碱，可与土壤中的酸性氧化物结合，既减少其对作物的毒害又降低土壤酸性物质的形成和残留。',
          trait3:'调节土壤微环境，其本身的有机成分及铜、硅等物质可以增加有益菌种群数量。',
          notice:'本品为高活性微生物制剂，应于阴凉干燥处密封保存。',
          notice4:"本品为超浓结晶，生长菌丝，结块不影响肥效。",
          abs:true
        },
        {
          id:8,
          name2: '上禾化肥',
          url: require('../assets/huafei/微生物菌剂3.jpg'),
          name1: ' 吉五福-微生物菌剂\n',
          kg:"净含量：20KG",
          kgs:"20KG",
          jszb:"腐植酸≥55%、富里酸≥10%、黄腐酸钾≥5%、有机质≥65%、海藻提取物≥5%、RE稀土元素≥20g/kg。",
          type: "有效活菌数≥2.0亿/克，全水溶腐植酸颗粒",
          range: '修复土壤 刺激增长 生根壮苗 缓释增效。',
          reference: "施肥或追肥：沟施，果树：每棵2-4公斤，蔬菜类作物：每亩50-100公斤，棉花及大田粮油类作物：每亩20-40公斤",
          trait:'本品具有强大的阳离子代换量，改良土壤结构、提高蓄保能力，增强土壤通透性。' ,
          trait1:'与土壤中金属离子结合，形成水磷性团粒结构' ,
          trait2:'调酸调碱，可与土壤中的酸性氧化物结合，既减少其对作物的毒害又降低土壤酸性物质的形成和残留。',
          trait3:'调节土壤微环境，其本身的有机成分及铜、硅等物质可以增加有益菌种群数量。',
          notice:'本品为高活性微生物制剂，应于阴凉干燥处密封保存。',
          notice4:"本品为超浓结晶，生长菌丝，结块不影响肥效。",
          abs:true
        },
        {
          id:9,
          name2: '上禾化肥',
          url: require('../assets/huafei/微生物菌剂.jpg'),
          name1: ' 吉五福-微生物菌剂\n',
          kg:"净含量：40KG",
          kgs:"40KG",
          type: "有效活菌数≥2.0亿/克，可以在菌肥上生种子的菌肥",
          range: '清理毒素 消除病菌 净化土壤 恢复生态 基因修复 植株复壮 品种驯化 优质高产。',
          reference: "底施、追施均可；大田每亩使用本品20-40千克；果树蔬菜每亩使用过本品80-120千克，也可根据土壤肥力酌情增减。",
          trait:'精华土壤功能：富含高密度活性碳，沉降吸附土壤重金属，清理毒素，消除有書病菌，净化土壤，恢复土壤生态平衡，预防植物根腐、黄叶、小叶、早衰等症。' ,
          trait1:'植株复壮功能：富含超短支链小分子碳，修复植物受损基因，激化作物品中特性，生根促长，强化吸收，果甜瓜香，叶肥果美，增糖增蜡，大幅度提高作物产量及品级。' ,
          trait2:'降解矿质元素：高活性微生物菌群有机结合，高效分工，源源不断降解被土壤固定锁住的死磷、死钾。剥离溶解士壤成土母质中的钙、镁、硫、铜、铁、锰、锌、钼等中微量元素,',
          trait3:'元素均衡供给:独特的纳米微孔技术，能自动调节土壤对根系碳源及氨源的供应，作物不脱肥，不徒长。作物干物质积累转化快，产量高，品质好，丰产性突出。',
          notice:'本品为高活性微生物制剂，应于阴凉干燥处密封保存。',
          notice1:"与其它化肥混用时，应现配现用，避免长时间存放。",
          notice2: "密封保质期3年，生产日期见合格证或封口处",
          notice4:"本品为超浓结晶，生长菌丝，结块不影响肥效。",
          abs:true
        },
      ],
    }
  },
  created() {
    console.log(this.$route.query)
    this.id = this.$route.query.id;
    console.log(this.id)
    console.log(this.arr[0].url);

    for(let i =0; i < this.arr.length; i++){
      if(!(i == this.id)) {
          this.arrlist.push(this.arr[i]);
        console.log(this.arrlist)
      }
    }

  },
  methods: {
    chanPin(id){
      this.arrlist = [];
      for(let i =0; i < this.arr.length; i++){
        // console.log(i)
        if(!(i == id)) {
          this.arrlist.push(this.arr[i]);
        }
      }
      this.id = id;
    },
    dianji(){
      console.log(55555);

    },

    callback(key) {
      console.log(key)
    },

  }
}
</script>

<style scoped>
* {
  margin-top: 0px;
  padding: 0px;
  border: 0 none;
  font: inherit;
  font-family: 微软雅黑;
}

.main {
  position: relative;
  width: 100%;
  z-index: 2;
}

.product {
  width: 1200px;
  /*border: 1px solid yellow;*/
  background-color: #fff;
  margin: 0 auto;
  margin-top: -40px;
  position: relative;

}

.left_bg {
  width: 29px;
  height: 174px;
  background: url("../assets/bg_con.png") no-repeat;
  position: absolute;
  left: -29px;
  top: 0;
}

.right_bg {
  width: 29px;
  height: 174px;
  background: url("../assets/bg_con (1).png") no-repeat -29px 0px;
  position: absolute;
  right: -29px;
  top: 0;
}

.main_text {
  height: 35px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 2;
  line-height: 35px;
  color: #30313a;
  font-family: "Microsoft YaHei";
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #cecece;
  padding-left: 148px;
  text-align: left;
}

.main_news {
  margin-bottom: 80px;
  overflow: hidden;
  width: 910px;
  margin: 0 auto;
  font-family: "Microsoft YaHei";
  color: #4c4948;
}

.DeatilImg {
  width: 470px;
  height: 417px;
  float: left;
  text-align: center;
}

.Deatil {
  margin-top: 72px;
}

.DeatilText {
  float: left;
  width: 430px;
}

.DeatilText h1 {
  font-size: 26px;
  color: #4c4948;
  text-align: left;

}

.DeatilText img {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-left: -350px;
}

.DeatilText p {
  font-size: 18px;
  color: #42b983;
  line-height: 28px;
  text-align: left;
}

.DeatilBtn {
  width: 169px;
  height: 41px;
  background: #d7232e;
  color: #fff;
  line-height: 41px;
  text-align: center;
  margin-top: 38px;
  cursor: pointer;
}

.DeatilBtn a {
  color: #fff;
  text-decoration: none;
}

.clr {
  clear: both;
}

.Overview {
  width: 910px;
}

.Overview_tabs {
  text-align: left;
  font-size: 16px;
  color: #4c4948;
  border: 1px solid #e2e2e1;
  height: 300px;
}

.Overview_tabs p {
  margin-left: 10px;
}


ol, ul {
  list-style: none outside none;
}

.other_text {
  font-size: 26px;
  color: #f5a11a;
  font-family: 'Microsoft YaHei';
  height: 32px;
  line-height: 32px;
  margin-top: 45px;
  float: left;
}

.Img_text {
  vertical-align: middle;
}

.Othertypes {
  width: 910px;
  height: 360px;
  position: relative;
  margin-top: 25px;
}

.Types {
  overflow: hidden;
  position: relative;
  width: 1350px;
}

.Types_shop {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.Types_shop li {
  height: 360px;
  float: left;
  width: 301px;
  border: 1px solid #e2e2e2;
  text-align: center;
  position: relative;
}

.Types_shop li img {
  margin-top: 24px;
  width: 180px;
  height: 213px;

}

.InfoShop {
  text-align: left;
  width: 301px;
  height: 126px;
  position: absolute;
  top: 260px;
  left: 30px;
}

.Knowledge {
  height: 474px;
  width: 910px;
  background: url("../assets/txt_left.png") no-repeat;
  background-position: left;
  margin-top: 90px;
}

.Knowledge_list {
  margin-left: 137px;
}

.Knowledge_list li {
  width: 50%;
  float: left;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  margin-top: 34px;
}

.Knowledge_list li a {
  color: #4c4948;
}

.Knowledge_list li a:hover {
  color: red;
}

.Knowledge_list li span {
  padding-left: 14px;
}
</style>